(function () {
    var body = document.querySelector('body');
    var header = document.querySelector(".main-header");
    var ivwHeader = document.querySelector('.ivw-header');
    var useAnimationFrame = (window.requestAnimationFrame !== undefined && window.cancelAnimationFrame !== undefined);
    var scrollAnimationFrame = 0;
    var scrollUp = "scroll-up";
    var scrollDown = "scroll-down";
    var lastScroll = 0;
    var headerHeight = header.offsetHeight + 870;
    var ivwHeaderHeight = 0;
    var placeholderMobileHeight = 56;
    var placeholder = document.querySelector('.header_placeholder');
    if (ivwHeader) {
        ivwHeaderHeight = ivwHeader.offsetHeight;
    }
    placeholder.style.height = placeholderMobileHeight+ivwHeaderHeight+"px";

    var stickyHeader = function() {
        var currentScroll = window.pageYOffset;

        if(header.classList.contains("sales-landingpage") === false) {
            if (currentScroll <= headerHeight) {
                body.classList.remove(scrollUp);
                header.classList.add('main-header--large');
                //return;
            } else if (currentScroll >= headerHeight) {
                header.classList.remove('main-header--large');
                //return;
            }
        }

        if (currentScroll > headerHeight && currentScroll > lastScroll &&
            !body.classList.contains(scrollDown)) {
            body.classList.remove(scrollUp);
            body.classList.add(scrollDown);
        } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
            body.classList.remove(scrollDown);
            body.classList.add(scrollUp);
        }
        lastScroll = currentScroll;
    };

    window.addEventListener("scroll", function () {
        if (useAnimationFrame) {
            window.cancelAnimationFrame(scrollAnimationFrame);
            scrollAnimationFrame = window.requestAnimationFrame(stickyHeader);
        } else {
            window.clearTimeout(scrollAnimationFrame);
            scrollAnimationFrame = window.setTimeout(stickyHeader, 100);
        }
    });
}());
