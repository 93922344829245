const setLayoutBreadcrumb = function () {

    const getViewport = function (e) {
        return e.getBoundingClientRect();
    }

    const getScrollWidth = function (e) {
        return e.scrollWidth;
    }

    const setUserScrollLayout = function setScrollLayout() {
        const breadcrumbWrapper = document.querySelector('.breadcrumb-wrapper');
        const breadcrumb = document.querySelector('.breadcrumb');
        if (breadcrumb !== null && breadcrumbWrapper !== null) {
            const viewport = getViewport(breadcrumb);
            const scrollWidth = getScrollWidth(breadcrumbWrapper);

            if (scrollWidth > viewport.width) {
                breadcrumb.classList.add('breadcrumb-blur');
                breadcrumbWrapper.classList.add('breadcrumb__flex-right');
                breadcrumbWrapper.scrollTo(scrollWidth, 0);
            }
        }
    }

    setUserScrollLayout();
}

setLayoutBreadcrumb();
