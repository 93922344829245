var lightboxPlugin = function () {

    var lightbox = document.querySelectorAll(".lightbox");

    var toggleAnimationFix = function(lightbox) {
        const animatedParent = lightbox.closest('.info-box__content');
        if (animatedParent) {
            if (animatedParent.dataset.animatable === undefined) {
                animatedParent.dataset.animatable = "1";
                animatedParent.classList.add('no-anim');
            } else {
                animatedParent.classList.remove('no-anim');
                animatedParent.removeAttribute('data-animatable');
            }
        }
    };

    [].forEach.call(lightbox, function (el) {
        const lightboxEnabled = el.querySelector('.lightbox--enabled');

        if(lightboxEnabled && window.getComputedStyle(lightboxEnabled).getPropertyValue('display') === "block") {
            const lightboxImage = el.querySelector('.lightbox--enabled__container__image-wrapper__img');
            const lightboxTag = el.querySelector('.lightbox--enabled__container__image-wrapper__tag');
            const lightboxBtnClose = el.querySelector('.lightbox--enabled__container__close-btn-wrapper__btn');
            const lightboxImageWrapper = el.querySelector('.lightbox--enabled__container__image-wrapper');

            lightboxBtnClose.addEventListener('click', (e) => {
                e.stopPropagation();
                toggleAnimationFix(e.target);
                hideLightbox();
            });

            window.addEventListener('keydown', (e) => {
                if(!lightboxEnabled.classList.contains('active')) return;
                if(e.key.includes('Escape')) {
                    e.stopPropagation();
                    toggleAnimationFix(e.target);
                    hideLightbox();
                }
            });

            lightboxEnabled.addEventListener('click', (e) => {
                e.stopPropagation();
                toggleAnimationFix(e.target);
                hideLightbox();
            });

            lightboxImageWrapper.addEventListener('click', (e) => {
                e.stopPropagation();
            })

            const showLightbox = () => {
                lightboxEnabled.classList.add('active');
            };
            const hideLightbox = () => {
                lightboxEnabled.classList.remove('active');
            };

            if(!!el.querySelector('.lightbox__gallery')) {
            
                const galleryContent = el.querySelectorAll('.lightbox__gallery__content');
                const galleryContentArray = Array.from(galleryContent);
                const galleryImages = el.querySelectorAll('.lightbox__gallery__content__image-wrapper__img');
                const galleryDescriptions = el.querySelectorAll('.lightbox__gallery__content__description-wrapper__description');
                const galleryTags = el.querySelectorAll('.lightbox__gallery__content__image-wrapper__tag');
                const galleryImagesArray = Array.from(galleryImages);
                const galleryLastImage = galleryImagesArray.length -1;
                const galleryDescriptionsArray = Array.from(galleryDescriptions);
                const galleryTagsArray = Array.from(galleryTags);

                const lightboxDescription = el.querySelector('.lightbox--enabled__container__operations__description-wrapper__description');
                const lightboxImageCounter = el.querySelector('.lightbox--enabled__container__operations__description-wrapper__image-counter');

                const lightboxBtns = el.querySelectorAll('.lightbox--enabled__container__operations__btn');

                const lightboxBtnLeft = el.querySelector('.prev-bubble');
                const lightboxBtnRight = el.querySelector('.next-bubble');

                let lightboxImageIndex;

                const setLightboxData = (img) => {
                    lightboxImageIndex = galleryImagesArray.indexOf(img);
                    lightboxImage.src = galleryContentArray[lightboxImageIndex].dataset.src;
                    lightboxImageCounter.textContent = "" + (lightboxImageIndex + 1) + "/" + galleryImagesArray.length;
                    lightboxTag.textContent = galleryTagsArray[lightboxImageIndex].textContent;
                    lightboxDescription.textContent = galleryDescriptionsArray[lightboxImageIndex].textContent;
                };

                const switchLightboxImageLeft = () => {
                    lightboxImageIndex === 0 ? setLightboxData(galleryImagesArray[galleryLastImage]) : setLightboxData(galleryImagesArray[lightboxImageIndex-1]);
                };

                const switchLightboxImageRight = () => {
                    lightboxImageIndex === galleryLastImage ? setLightboxData(galleryImagesArray[0]) : setLightboxData(galleryImagesArray[lightboxImageIndex+1]);
                };

                const switchLightboxImage = (btnClassList) => {
                    btnClassList.contains('prev-bubble') ? switchLightboxImageLeft() : switchLightboxImageRight();
                };


                galleryImages.forEach(img => {
                    img.addEventListener('click', (e) => {
                        e.stopPropagation();
                        showLightbox();
                        setLightboxData(img);
                    });
                    const icon = img.closest('.lightbox__gallery__content__image-wrapper').querySelector('.lightbox__gallery__content__image-wrapper__icon');
                    if (icon) {
                        icon.addEventListener('click', (e) => {
                            e.stopPropagation();
                            showLightbox();
                            setLightboxData(img);
                        })
                    }
                });

                lightboxBtns.forEach(btn => {
                    btn.addEventListener('click', (e) => {
                        e.stopPropagation();
                        switchLightboxImage(e.currentTarget.classList);
                    });
                });

                window.addEventListener('keydown', (e) => {
                    if(!lightboxEnabled.classList.contains('active')) return;
                    if(e.key.includes('Left') || e.key.includes('Right')) {
                        e.preventDefault();
                        e.stopPropagation();
                        e.key.includes('Left') ? switchLightboxImageLeft() : switchLightboxImageRight();
                    }
                });
            } else {

                const singleImage = el.querySelector('.lightbox__single-image__content__image-wrapper__img');
                const lightBoxIcon = el.querySelector('.lightbox__single-image__content__image-wrapper__icon');
                const singleImageContent = el.querySelector('.lightbox__single-image__content');
                const singleImageDescription = el.querySelector('.lightbox__single-image__content__description-wrapper__description');
                const singleImageTag = el.querySelector('.lightbox__single-image__content__description-wrapper__tag');
                const singleImageLightboxDescription = el.querySelector('.lightbox--enabled__container__image-wrapper__description');

                const setLightboxData = () => {
                    lightboxImage.src = singleImageContent.dataset.src;
                    lightboxTag.textContent = singleImageTag.textContent;
                    singleImageLightboxDescription.textContent = singleImageDescription.textContent;
                };

                singleImage.addEventListener('click', (e) => {
                    e.stopPropagation();
                    toggleAnimationFix(e.target);
                    showLightbox();
                    setLightboxData();
                });
                lightBoxIcon.addEventListener('click', (e) => {
                    e.stopPropagation();
                    toggleAnimationFix(e.target);
                    showLightbox();
                    setLightboxData();
                });
            }
        }
    });
};

lightboxPlugin();