document.addEventListener('DOMContentLoaded', function() {
    var cmpContents = document.querySelectorAll('.cmp-consent__switch--wrapper');
    if (cmpContents.length > 0) {
        cmpContents.forEach(function(c) {
            let domain = location.host.split('.');
            if (domain.length > 2) {
                domain.splice(0, 1);
            }
            domain = domain.join('.');
            c.addEventListener('click', function(e) {
                const checked = e.target.parentElement.querySelector('input').checked;
                if (!checked) {
                    Cookies.set('consent-embedded', true, {
                        expires: 90,
                        path: '/',
                        domain: domain,
                    });
                    __tcfapi('postCustomConsent', 2, () => {
                    }, [], ['61b71625903e05075af967ce'], []);
                } else {
                    Cookies.remove('consent-embedded');
                }
                const urlParts = location.href.split('?');
                if (urlParts[0].slice(-4) === '/amp') {
                    location.href = urlParts[0].slice(0, -4)+(urlParts[1] ? '?'+urlParts[1] : '');
                } else {
                    window.location.reload();
                }
            }, {once: true});
        });
    }
});
