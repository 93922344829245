document.addEventListener('DOMContentLoaded', function() {
   var nav = document.querySelector('nav.navigation');
   var body = document.querySelector('body');
   var isMobile = nozmhnHelper.isAndroid() || nozmhnHelper.isIos();

   if (nav && isMobile) {
      var subMenus = nav.querySelectorAll('.navigation__link--sub');
      subMenus.forEach(function(sub) {
         sub.addEventListener('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            // body.addEventListener('click', function() {
            //    resetAll();
            // });
            var target = e.target;
            var parent = target.nodeName.toLowerCase() === 'li' ? target : target.closest('li');

            if (parent.classList.contains('active')) {
               var href = target.getAttribute('href');
               if (href) {
                  location = href;
               }
            }

            toggleActive(parent);
         })
      });
   }

   var resetAll = function() {
      subMenus.forEach(function(s) {
         s.closest('li').classList.remove('active');
      });
   }

   var toggleActive = function(li) {
      if (!li.classList.contains('active')) {
         resetAll();
      }
      li.classList.toggle('active');
   }

   // scroll fix
   const menuCheckbox = document.querySelector('#main-nav');
   const header = document.querySelector('header.main-header');
   if (menuCheckbox) {
      // const animation = "translate3d(0,-100%,0);";
      menuCheckbox.addEventListener('change', function(e) {
         const checkbox = e.target;
         if (checkbox.checked) {
            console.log("set style");
            header.style.transform = "unset";
         } else {
            console.log("unset style");
            header.style.removeProperty('transform');
         }
      });
   }
});
