document.addEventListener("DOMContentLoaded", function() {
    document.querySelectorAll('.overlay-modal-close').forEach(function(e) {
        e.addEventListener('click', function() {
            closeOverlay(e);
        });
    });

    var closeOverlay = function(e) {
        e.closest('.overlay').remove();
    };
});