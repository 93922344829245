document.addEventListener('DOMContentLoaded', function() {
    // Navigation tracking
    document.querySelector('.top-part').addEventListener('click', (e) => {
       if (e.target && e.target.matches('a')) {
           const navBar = 'Portal Links';
           const navItem = gtmHelper.getNavItemData(e);

           gtmHelper.navTracking({navBar: navBar, navItem: navItem});
       }
    });

    document.querySelector('nav.navigation').addEventListener('click', (e) => {
        if (e.target && e.target.matches('a')) {
            const navBar = 'Main Navigation';
            const navItem = gtmHelper.getNavItemData(e);

            gtmHelper.navTracking({navBar: navBar, navItem: navItem});
        }
    });

    document.querySelector('.main-nav').addEventListener('click', (e) => {
        const el = e.target.closest('a');
        if (el ) {
            const navBar = 'Main Navigation';
            const navItem = gtmHelper.getNavItemData(el);

            gtmHelper.navTracking({navBar: navBar, navItem: navItem});
        }
    });

    document.querySelector('.footer__content').addEventListener('click', (e) => {
        if (e.target && (e.target.matches('a') || e.target.closest('.footer__content__link'))) {
            const navBar = 'Main Navigation';
            const navItem = gtmHelper.getNavItemData(e);

            gtmHelper.navTracking({navBar: navBar, navItem: navItem});
        }
    });

    // search tracking
    document.querySelectorAll('form[name="search"]').forEach(function(s) {
        s.addEventListener('submit', function(e) {
            const form = e.target;
            const input = form.querySelector('input');
            let searchBar = '';
            if (input) {
                const val = input.value;
                const formId = form.getAttribute('id');
                if (formId) {
                    searchBar = gtmHelper.getSearchBar(formId.toLowerCase());
                }

                if (val && searchBar) {
                    gtmHelper.gtmPush({
                        data: {
                            event: 'gaEvent',
                            event_name: 'search',
                            search: {
                                search_bar: searchBar,
                                search_term: val,
                                event_category: 'search',
                                event_action: searchBar,
                                event_label: val,
                            },
                        },
                    });
                }
            }
        });
    });

    // User tracking - Update personal data
    document.querySelectorAll(
        'nav.personal-menu a[title="Zugangsdaten ändern"]').
        forEach(function(a) {
            a.addEventListener('click', function(e) {
                const button = e.target;
                gtmHelper.gtmPush({
                    data: {
                        event: 'gaEvent',
                        event_name: 'account_modification',
                        account_modification: {
                            modification_selection: 'change',
                            event_category: 'account',
                            event_action: 'account_modification',
                            event_label: 'change',
                            event_action: 'modification',
                        },
                    },
                });
            });
        });

    const socialShare = document.querySelector('.social-share');
    if (socialShare) {
        socialShare.addEventListener('click', (e) => {
            const aTarget = e.target.closest('a');
            if (aTarget && !aTarget.classList.contains('feedback')) {
                const artTitle = gtmHelper.getArticleTitle();
                let target = e.target;
                let type = '';

                if (target.nodeName.toLowerCase() === 'button') {
                    type = 'feedback';
                } else if (target.nodeName.toLowerCase() !== 'a') {
                    target = target.closest('a');
                    type = gtmHelper.getSocialShareType(target);
                }

                gtmHelper.gtmPush({
                    data: {
                        event: 'gaEvent',
                        event_name: 'share',
                        share: {
                            article_id: pageInfoData.article_id,
                            article_name: artTitle,
                            share_type: type,
                            event_category: 'social_share',
                            event_action: 'article',
                            event_label: type,
                        },
                    },
                });
            }
        });
    }

    const switchBox = document.querySelector('.switch-box');
    if (switchBox) {
        switchBox.addEventListener('click', (e) => {
            if (e.target && e.target.matches('a')) {
                const location = e.target.text.trim().replace('Region', '').trim();
                gtmHelper.gtmPush({
                    data: {
                        event: 'gaEvent',
                        event_name: 'location_switch',
                        location_switch: {
                            switch_location: location,
                            event_category: 'location_switch',
                            event_action: 'switch',
                            event_label: location,
                        },
                    },
                });
            }
        });
    }

    const articleTeaserOpener = document.querySelector('.article__teaser--opener');
    if (articleTeaserOpener) {
        articleTeaserOpener.addEventListener('click', (e) => {
            let articleId = e.target.closest(
                '.article__teaser--opener').dataset.articleId;
            articleId = articleId ? articleId : 0;
            if (articleId) {
                gtmHelper.gtmPush({
                    data: {
                        event: 'gaEvent',
                        event_name: 'lead_story',
                        lead_story: {
                            lead_story: 'lead',
                            article_id: articleId,
                            event_category: 'lead_story',
                            event_action: 'lead',
                            event_label: articleId,
                        },
                    },
                });
            }
        });
    }

    const nowImportantBox = document.querySelector('.now-important-box');
    if (nowImportantBox) {
        nowImportantBox.addEventListener('click', (e) => {
            let articleId = e.target.closest(
                '.slider-element').dataset.articleId;
            articleId = articleId ? articleId : 0;
            gtmHelper.gtmPush({
                data: {
                    event: 'gaEvent',
                    event_name: 'lead_story',
                    lead_story: {
                        lead_story: 'related_content',
                        article_id: articleId,
                        event_category: 'lead_story',
                        event_action: 'related_content',
                        event_label: articleId,
                    },
                },
            });
        });
    }

    // paywall tracking
    let paywall = document.querySelector('.paywall:not(.paywalled-content)');
    if (paywall) {
        const paywallType = paywall.dataset.paywallType || '';
        const paywallObserverOptions = {
            threshold: 0.5,
        };
        const articleId = gtmHelper.getCurrentArticle()?.id;
        const paywallObserver = new IntersectionObserver(function (entries, observer) {
            if (entries[0].intersectionRatio > 0.5) {
                gtmHelper.gtmPush({
                    data: {
                        event: 'gaEvent',
                        event_name: 'paywall_article_view',
                        paywall_article_view: {
                            event_category: 'paywall',
                            event_action: 'shown'+paywallType,
                            event_label: articleId,
                        },
                    },
                });
                observer.unobserve(paywall);
            }
        }, paywallObserverOptions);
        paywallObserver.observe(paywall);

        paywall.querySelectorAll('.paywall-button-register').forEach(function (a) {
            a.addEventListener('click', function (e) {
                gtmHelper.gtmPush({
                    data: {
                        event: 'gaEvent',
                        event_name: 'paywall_article_click',
                        paywall_article_click: {
                            event_category: 'paywall_article_click',
                            event_action: 'registrierung',
                            event_label: articleId,
                        },
                    },
                });
            });
        });

        paywall.querySelectorAll('.paywall-button-month-for-free').forEach(function (a) {
            a.addEventListener('click', function (e) {
                gtmHelper.gtmPush({
                    data: {
                        event: 'gaEvent',
                        event_name: 'paywall_article_click',
                        paywall_article_click: {
                            event_category: 'paywall_article_click',
                            event_action: 'probemonat',
                            event_label: articleId,
                        },
                    },
                });
            });
        });

        paywall.querySelectorAll('.paywall-button-welect').forEach(function (a) {
            a.addEventListener('click', function (e) {
                gtmHelper.gtmPush({
                    data: {
                        event: 'gaEvent',
                        event_name: 'paywall_article_click',
                        paywall_article_click: {
                            event_category: 'account',
                            event_action: 'click',
                            event_label: 'welect',
                        },
                    },
                });
            });
        });

        paywall.querySelectorAll('.paywall-button-paypal').forEach(function (a) {
            a.addEventListener('click', function (e) {
                gtmHelper.gtmPush({
                    data: {
                        event: 'gaEvent',
                        event_name: 'paywall_article_click',
                        paywall_article_click: {
                            event_category: 'paywall_article_click',
                            event_action: 'paypal',
                            event_label: articleId,
                        },
                    },
                });
            });
        });
    }

    // smartbanner tracking
    document.addEventListener('smartbanner.clickout', function(e) {
        const banner = e.target.querySelector('.smartbanner');
        const deviceOS = banner.classList.contains('smartbanner--android') ?
            'android' :
            'ios';
        gtmHelper.gtmPush({
            data: {
                event: 'gaEvent',
                event_name: 'smartbanner',
                smartbanner: {
                    banner_type: deviceOS,
                    event_category: 'smartbanner',
                    event_action: deviceOS,
                    event_label: '',
                },
            },
        });
    });

    //feedback tracking
    document.querySelectorAll('button.feedback__form__submit').
        forEach(function(a) {
            a.addEventListener('click', function(e) {
                const form = e.target.closest('form');
                if (form) {
                    let topic = form.querySelector('select').value;

                    if (topic) {
                        gtmHelper.gtmPush({
                            data: {
                                event: 'gaEvent',
                                event_name: 'feedback_submit',
                                feedback_submit: {
                                    feedback_topic: topic,
                                    event_category: 'feedback',
                                    event_action: 'submit',
                                    event_label: topic,
                                },
                            },
                        });
                    }
                }
            });
        });

    //personalization tracking
    document.addEventListener('personalArticleBoxSelectedInitialized',
        function(e) {
            e.target.querySelectorAll('.dropdown').forEach(function(a) {
                a.addEventListener('personalArticleBoxSelected', function(e) {
                    const parent = e.target.closest('.article-personalisation');
                    const parts = e.detail.split('##');
                    const currLoc = parts[0];
                    const currPub = parts[1];
                    const articlePersonalizationElements = document.querySelectorAll(
                        '.article-personalisation');

                    var location = [];
                    var publication = [];
                    if (articlePersonalizationElements.length > 0) {
                        articlePersonalizationElements.forEach(function(box) {
                            if (parent === box) {
                                location.push(currLoc);
                                publication.push(currPub);
                            } else if (box.dataset.loc && box.dataset.pub) {
                                location.push(box.dataset.loc);
                                publication.push(box.dataset.pub);
                            } else {
                                const dropDown = box.querySelector('.dropdown');
                                const selected = dropDown.querySelector('[data-selected="true"]');
                                if (selected) {
                                    location.push(selected.innerText);
                                    publication.push(
                                        selected.closest('[class^=dropdown-level]').
                                            querySelector(
                                                '.dropdown-header').childNodes[0].textContent.trim());
                                }
                            }
                        });
                    }

                    gtmHelper.gtmPush({
                        data: {
                            event: 'gaEvent',
                            event_name: 'location_selection',
                            location_selection: {
                                selected_location: location.join(),
                                publication_title: publication.join(),
                                event_category: 'location_selection',
                                event_action: publication,
                                event_label: location,
                            },
                        },
                    });
                });
            });
        });

    document.addEventListener('personalArticleBoxResetted', function(e) {
        const {location, publication} = gtmHelper.getLocationPublicationArtPersonlization();

        gtmHelper.gtmPush({
            data: {
                event: 'gaEvent',
                event_name: 'location_reset',
                location_reset: {
                    event_category: 'location_reset',
                    event_action: 'submit',
                    event_label: 'Auswahl zurücksetzen',
                },
            },
        });

        window.dataLayer.push(function() {
            this.reset();
        });
    });

    // article reading tracking
    const articlePage = document.querySelector(
        'article.content--group__article');
    if (articlePage) {
        var isPaywallVisible = document.querySelector('.paywall:not(.paywalled-content)') !== null;
        var articleBottom = articlePage.getBoundingClientRect().height;
        var finishedReadingHandler = function() {
            if ((window.innerHeight + window.pageYOffset) >= articleBottom) {
                gtmHelper.gtmPush({
                    data: {
                        event: 'gaEvent',
                        event_name: 'article_finish_reading',
                        article_finish_reading: {
                            article_name: pageInfoData.article_name,
                            article_id: pageInfoData.article_id,
                            article_author: pageInfoData.article_author,
                            content_type: pageInfoData.content_type,
                            article_type: pageInfoData.article_type,
                            article_length: pageInfoData.article_length,
                            article_keywords: pageInfoData.article_keywords,
                            article_dateOrigin: pageInfoData.article_dateOrigin,
                            article_date: pageInfoData.article_date,
                            event_category: 'article',
                            event_action: 'finish_reading',
                            event_label: pageInfoData.article_id,
                            container_id: pageInfoData.container_id,
                            cu_ids: pageInfoData.cu_ids,
                        },
                    },
                });

                document.removeEventListener('scroll', finishedReadingHandler);
            }
        };

        gtmHelper.gtmPush({
            data: {
                event: 'gaEvent',
                event_name: 'article_view',
                article_view: {
                    article_name: pageInfoData.article_name,
                    article_author: pageInfoData.article_author,
                    article_type: pageInfoData.article_type,
                    article_length: pageInfoData.article_length,
                    article_keywords: pageInfoData.article_keywords,
                    article_dateOrigin: pageInfoData.article_dateOrigin,
                    article_date: pageInfoData.article_date,
                    iab_category: pageInfoData.iab_categorie,
                    event_category: 'article',
                    event_action: 'view',
                    event_label: pageInfoData.article_id,
                    container_id: pageInfoData.container_id,
                    cu_ids: pageInfoData.cu_ids,
                },
            },
        });

        if (!isPaywallVisible) {
            document.addEventListener('scroll', finishedReadingHandler);
        }
    }

    // xl Story tracking
    const xlCarousel = document.querySelector('.xl-carousel-box');
    if (xlCarousel) {
        xlCarousel.addEventListener('click', (e) => {
           const element = e.target.closest('a');
           let url = element.getAttribute('href');
           let articleId = url.slice(url.lastIndexOf('/') + 1);
           gtmHelper.gtmPush({
               data: {
                   event: 'gaEvent',
                   event_name: 'xl_click',
                   xl_click: {
                       xl_id: articleId,
                       event_category: 'xl-carousel',
                       event_action: 'click',
                       event_label: articleId,
                   },
               },
           });
        });
    }

    // Module interactions
    document.querySelectorAll(
        '.slider-box .slider-box__more, .podcast-element__episodes-link, .article__teaser--graphic .article__teaser__info').
        forEach(function(trackedLink) {
            trackedLink.addEventListener('click', function() {
                const slider = trackedLink.closest('.slider-box');
                const breadcrumb = document.querySelector('.breadcrumb');

                var moduleName = '',
                    moduleType = '',
                    moduleLocation = '';

                if (slider) {
                    moduleType = gtmHelper.getSliderType(slider);
                    moduleName = gtmHelper.getModuleNameForSlider({
                        'sliderType': moduleType,
                        'slider': slider,
                        'sliderMore': trackedLink,
                    });
                } else if (trackedLink.closest('.article__teaser--graphic')) {
                    moduleType = 'graphic-teaser';
                    moduleName = trackedLink.closest('.article__teaser, .podcast-element').
                        querySelector(
                            '.article__teaser__headline, .podcast-element__title').innerText; // ..still not clear how this should be named
                } else {
                    moduleType = 'info-teaser';
                    moduleName = trackedLink.closest('.article__teaser, .podcast-element').
                        querySelector(
                            '.article__teaser__headline, .podcast-element__title').innerText; // ..still not clear how this should be named
                }
                if (breadcrumb) {
                    moduleLocation = breadcrumb.querySelector(
                        'a:last-of-type').innerText;
                } else if (location.pathname === '/') {
                    moduleLocation = 'home';
                }

                gtmHelper.gtmPush({
                    data: {
                        event: 'gaEvent',
                        event_name: 'module_interaction',
                        module_interaction: {
                            module_type: moduleType,
                            module_name: moduleName,
                            event_category: 'module_interaction',
                            event_action: (moduleType === 'newsticker' || moduleType ===
                            'theme-event' || moduleType === 'info-teaser' || moduleType ===
                            'graphic-teaser' ?
                                moduleLocation :
                                'content_slider'), // Page on which the action took place
                            event_label: moduleName,
                            module_location: moduleLocation,
                        },
                    },
                });
            });
        });

    // newsticker
    const newsticker = document.querySelectorAll('.newsticker__container__items--item a');
    if (newsticker.length) {
        [].forEach.call(newsticker, function (el) {
            el.addEventListener('click', function() {
                let url = this.getAttribute('href');
                let articleId = url.slice(url.lastIndexOf('-') + 1).split('?')[0];
                gtmHelper.gtmPush({
                    data: {
                        event: 'gaEvent',
                        event_name: 'newsticker_click',
                        event_category: 'newsticker',
                        event_action: 'click',
                        event_label: articleId,
                    },
                });
            });
        });
    }
});

/**
 * GTM Helper
 */
var gtmHelper = {
    getCurrentArticle: function() {
        let article = false;

        article = {};
        if (typeof c1_response !== 'undefined' && !article) {
            // article.id = c1_response['c1-cms-id'];
            article.kicker = c1_response['c1-track-kicker'];
            article.headline = c1_response['c1-track-heading'];
        }

        article.id = pageInfoData.article_id;

        return article;
    },

    gtmPush: function({data}) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
    },

    getNavItemData: function(e) {
        let link = e;
        if (!e.tagName) {
            link = e.target;
        }
        let text = '';
        if (link.tagName.toLowerCase() !== 'a') {
            link = link.closest('a');
        }

        text = link.innerText.trim();
        if (text.trim() === '') {
            text = link.title;
        }

        return text;
    },

    navTracking: function({navBar = 'Main Navigation', navItem = ''}) {
        this.gtmPush({
            data: {
                event: 'gaEvent',
                event_name: 'navigation',
                navigation: {
                    navigation_bar: navBar,
                    navigation_item: navItem,
                    event_category: 'navigation',
                    event_action: navBar,
                    event_label: navItem,
                },
            },
        });
    },

    getSearchBar: function(id) {
        let searchBar = '';
        switch (id) {
            case 'error404':
                searchBar = '404-Page';
                break;
            case 'searchpage':
                searchBar = 'Search Page';
                break;
            case 'foot':
                searchBar = 'footer';
                break;
            default:
                searchBar = 'Main Navigation';
                break;
        }

        return searchBar;
    },

    getArticleTitle: function() {
        return document.querySelector('.meta-box__headline').textContent.trim();
    },

    getSocialShareType: function(e) {
        let type = '';
        if (e.classList.contains('social-share__button--fb')) {
            type = 'facebook';
        } else if (e.classList.contains('social-share__button--twitter')) {
            type = 'twitter';
        } else if (e.classList.contains('social-share__button--whatsapp')) {
            type = 'whatsapp';
        } else if (e.classList.contains('social-share__button--email')) {
            type = 'email';
        } else if (e.classList.contains('social-share__button--print')) {
            type = 'print';
        } else {
            type = 'feedback';
        }

        return type;
    },

    getSliderType: function(slider) {
        const sliderClasses = slider.classList;
        if (sliderClasses.contains('media-slider-box')) {
            return 'media-slider';
        } else if (sliderClasses.contains('podcast-box')) {
            return 'teaser';
        } else if (sliderClasses.contains('real-estate-box')) {
            return 'real-estate';
        } else if (sliderClasses.contains('job-box')) {
            return 'jobs';
        } else if (sliderClasses.contains('event-slider-box')) {
            return 'events';
        } else if (sliderClasses.contains('newsticker-box')) {
            return 'newsticker';
        } else if (sliderClasses.contains('theme-event-box')) {
            return 'theme-event';
        }

        return false;
    },

    getModuleNameForSlider: function({sliderType, slider, sliderMore}) {
        switch (sliderType) {
            case 'newsticker':
                return sliderMore.innerText;
                break;
            case 'theme-event':
            case 'media-slider':
            case 'teaser':
                return slider.querySelector('.box-name').innerText;
                break;
            case 'real-estate':
                return 'Alle Immobilien';
                break;
            case 'jobs':
                return 'Alle Jobs';
                break;
            case 'events':
                return 'Alle Veranstaltungen';
                break;
            default:
                return false;
        }
    },

    getLocationPublicationArtPersonlization: function() {
        const location = [];
        const publication = [];
        const clones = [];
        const articlePersonalizationElements = document.querySelectorAll(
            '[data-type="ap-article-loaded"].article-personalisation .article-personalisation__wrapper, [data-pub].article-personalisation .article-personalisation__wrapper');

        articlePersonalizationElements.forEach((e) => {
            clones.push({
                clone: e.cloneNode(true),
                data: e.closest('.article-personalisation').dataset,
            });
        });

        if (clones.length > 0) {
            clones.forEach(function(clone) {
                const dropDown = clone.clone.querySelector('.dropdown');
                const selected = dropDown.querySelector('[data-selected="true"]');
                if (clone.data.loc && clone.data.pub) {
                    location.push(clone.data.loc);
                    publication.push(clone.data.pub);
                } else {
                    location.push(selected.innerText);
                    publication.push(
                        selected.closest('[class^=dropdown-level]').
                            querySelector('.dropdown-header').childNodes[0].textContent.trim());
                }
            });
        }

        return {
            location: location,
            publication: publication,
        };
    },
};