var myAreas = [];

var articlePersonalizationPlugin = function (selector) {

    var loader = '<i class="loader--ellipsis">' +
        '    <i class="loader--ellipsis-item"></i>' +
        '    <i class="loader--ellipsis-item"></i>' +
        '    <i class="loader--ellipsis-item"></i>' +
        '    <i class="loader--ellipsis-item"></i>' +
        '</i>';

    const saveToProfile = (ids) => {
        if (tp?.pianoId?.isUserValid()) {
            fetch(`${
                nozmhnHelper.environment() == 'prd' ? 'https://api-eu.piano.io' : 'https://sandbox.piano.io'
            }/id/api/v1/identity/userinfo?aid=${tp.aid}&access_token=${tp.pianoId.getToken()}&lang=de_DE`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json; charset=UTF-8' },
                body: JSON.stringify({
                    uid: tp.pianoId.getUser().uid,
                    form_name: 'article-personalization',
                    custom_field_values: [{
                        field_name: 'portalLocationFavourite',
                        value: ids,
                    }],
                }),
            });
        }

        /**
         * Saving to C1 (remove after Piano rollout)
         */
        var xhr = new XMLHttpRequest();
        var url = "/api/user/property/portalLocationFavourite";
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                var json = JSON.parse(xhr.responseText);
                console.log(json);
            }
        };
        var data = JSON.stringify({"value": ids});
        xhr.send(data);
    };

    const saveToCookie = (ids) => {
        const expirationDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365).toUTCString();
        document.cookie = `portalLocationFavourite=${ids}; expires=${expirationDate}; path=/`;
    };

    var $articlePersonalizationElements = document.querySelectorAll(selector);

    var articlePersonalisationHTML = '';

    const getSelectedLocations = () => {
        let ids = [];
        document.querySelectorAll(selector).forEach((box) => {
            let id = box.hasAttribute('data-loc') ? Number.parseInt(document.querySelector('.dropdown-item[title="'+CSS.escape(box.dataset.loc)+'"]').dataset.id) : box.dataset.areaId;
            ids.push(id);
        });

        return ids;
    }

    const resetHiddenDropDown = () => {
      document.querySelectorAll('.dropdown-item.hidden').forEach((d) => {
         d.classList.remove('hidden');
      });
    };

    const updateDropDowns = () => {
        resetHiddenDropDown();
        const ids = getSelectedLocations();
        ids.forEach((id) => {
            document.querySelectorAll('.article-personalisation [data-id="'+CSS.escape(id)+'"]').forEach((e) => {
              e.classList.add('hidden');
            })
        })
    };

    // const
    var countElements = 3;
    var articleLoadedClass = 'ap-article-loaded';

    var apBoxes = [];

    [].forEach.call($articlePersonalizationElements, function ($thisArticlePersonalization, index) {

        var $dropdownElements = $articlePersonalizationElements[index].querySelectorAll(".dropdown-ap");
        var $boxes = $articlePersonalizationElements[index].querySelectorAll('.box');
        var $articleContainer = $articlePersonalizationElements[index].querySelectorAll('.article-personalisation__container');
        var $articlePersonalizationResetElements = $articlePersonalizationElements[index].querySelectorAll('.article-personalisation-reset');
        var $dropdownItems = $articlePersonalizationElements[index].querySelectorAll('.dropdown-item');

        $thisArticlePersonalization.querySelectorAll('.dropdown-header').forEach(function ($thisDropdownHeader) {
            $thisDropdownHeader.addEventListener('click', function () {
                if (this.parentNode.classList.contains("dropdown-open")) {
                    this.parentNode.classList.remove("dropdown-open");
                } else {
                    this.parentNode.classList.add("dropdown-open");
                }
            });
        });

        if ($articlePersonalizationElements[index].getAttribute('data-type') !== articleLoadedClass) {
            var parser = new DOMParser();
            var doc = parser.parseFromString($articlePersonalizationElements[index].outerHTML, "text/html");
            doc.querySelector(".dropdown-header--text").innerText = 'Weiteren Ort wählen';
            articlePersonalisationHTML = doc.querySelector(selector).outerHTML;
        } else {
            $articlePersonalizationElements[index].classList.add(articleLoadedClass);
            $articlePersonalizationResetElements[0].classList.remove('hide');
            myAreas[index] = $articlePersonalizationElements[index].getAttribute('data-area-id');
        }

        $articlePersonalizationElements[index].querySelectorAll('.dropdown-item').forEach(function ($thisDropDownItem) {

            if ($thisDropDownItem.getAttribute('data-selected') === 'true') {
                $dropdownElements[0].querySelector('.dropdown-header--text').innerText = $thisDropDownItem.innerText;
            }

            $thisDropDownItem.addEventListener('click', function (e) {
                e.preventDefault();

                var id = this.getAttribute('data-id');
                var href = this.getAttribute('href');
                var target = e.target.hasOwnProperty('length') ? e.target[0] : e.target;
                var publication = target.closest('.dropdown-content').previousElementSibling.innerText;
                var location = target.innerText;
                const event_ = new CustomEvent('personalArticleBoxSelected', {
                    detail: location + '##' + publication,
                });
                this.closest('.dropdown-level-1').querySelectorAll('.dropdown-item').forEach((i) => {
                    i.dataset.selected = '';
                });
                $dropdownElements[0].dispatchEvent(event_);

                if ($boxes[0].classList.contains("hide")) {
                    $articleContainer[0].classList.add('loading-layer');
                    $articleContainer[0].insertAdjacentHTML('afterbegin', loader);
                } else {
                    $boxes[0].classList.add('loading-layer');
                    $boxes[0].insertAdjacentHTML('afterbegin', loader);
                }

                $dropdownItems[index].querySelectorAll('.dropdown-header').forEach(function ($thisDropdownHeader) {
                    if ($thisDropdownHeader.parentNode.classList.contains("dropdown-open")) {
                        $thisDropdownHeader.parentNode.classList.remove("dropdown-open");
                    } else {
                        $thisDropdownHeader.parentNode.classList.add("dropdown-open");
                    }
                });

                var dropdownOpen = $articlePersonalizationElements[index].querySelectorAll('.dropdown-open');
                [].forEach.call(dropdownOpen, function (thisDropdownOpen) {
                    thisDropdownOpen.classList.remove('dropdown-open');
                });

                // ajax call
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'text';

                xhr.onload = function () {
                    if (xhr.status >= 200 && xhr.status < 300) {

                        // request success
                        $dropdownElements[0].querySelector('.dropdown-header .dropdown-header--text').innerText = $thisDropDownItem.innerText;

                        var responseText = xhr.response;
                        var parser = new DOMParser();
                        var doc = parser.parseFromString(responseText, "text/html");
                        var fetchedArticles = doc.querySelector(".content--group");

                        if (fetchedArticles !== null) {

                            var fetchedArticlesHTML = fetchedArticles.outerHTML;

                            $boxes[0].classList.add('hide');
                            $articlePersonalizationResetElements[0].classList.remove('hide');
                            $articleContainer[0].innerHTML = "";
                            $articleContainer[0].insertAdjacentHTML('afterbegin', fetchedArticlesHTML);

                            // RENDER ACTION
                            if (index < countElements - 1) {
                                if (!$articlePersonalizationElements[index].classList.contains(articleLoadedClass)) {
                                    for (var i = 0; i < $articlePersonalizationElements.length; i++) {
                                        $articlePersonalizationElements[i].classList.add(articleLoadedClass);
                                        // store elements in array
                                        apBoxes[i] = $articlePersonalizationElements[i].cloneNode(true);
                                        // remove from dom
                                        $articlePersonalizationElements[i].innerHTML = '';
                                    }

                                    //render elements in dom from array
                                    for (var j = 0; j < apBoxes.length; j++) {

                                        if (j === apBoxes.length - 1) {
                                            $articlePersonalizationElements[j].insertAdjacentHTML('afterend', articlePersonalisationHTML);
                                        }

                                        $articlePersonalizationElements[j].insertAdjacentHTML('afterend', apBoxes[j].outerHTML);
                                        $articlePersonalizationElements[j].outerHTML = '';
                                    }

                                    // initialize elements
                                    articlePersonalizationPlugin('.article-personalisation');
                                }
                            } else {
                                $articlePersonalizationElements[index].classList.add(articleLoadedClass);
                            }

                            $articleContainer[0].classList.remove('loading-layer');

                            // set c1 property
                            myAreas[index] = id;
                            //console.log(myAreas);
                            //console.log(myAreas.join(","));
                            var tmpArticlePersonalizationElements = document.querySelectorAll('.article-personalisation.ap-article-loaded');
                            if (tmpArticlePersonalizationElements.length) {
                                tmpArticlePersonalizationElements[index].setAttribute('data-pub', publication);
                                tmpArticlePersonalizationElements[index].setAttribute('data-loc', location);
                            }
                            saveToCookie(myAreas.join(","));
                            saveToProfile(myAreas.join(","));
                            updateDropDowns();
                        } else {
                            $boxes[0].classList.remove('loading-layer');
                            $boxes[0].classList.remove('hide');
                            $boxes[0].innerHTML = '<span>Keine passenden Artikel vorhanden</span>';
                            $articleContainer[0].innerHTML = "";
                            $articlePersonalizationResetElements[0].classList.add('hide');

                        }
                    } else {
                        // The request failed!
                        $boxes[0].classList.remove('loading-layer');
                        $boxes[0].classList.remove('hide');
                        $boxes[0].innerHTML = '<span class="error text-color__danger">Beim Laden der Artikel ist ein Fehler aufgetreten.</span>';
                    }
                };
                // example json
                xhr.open('POST', href, true);
                xhr.send();
            });
        });

        // RESET ACTION
        $articlePersonalizationElements[index].querySelectorAll('.article-personalisation-reset').forEach(function ($thisResetElement) {

            $thisResetElement.addEventListener('click', function () {

                var $parentNode = $articlePersonalizationElements[0].parentNode;

                // store in array and remove from dom
                for (var i = 0; i < $articlePersonalizationElements.length; i++) {
                    // store elements in array
                    apBoxes[i] = $articlePersonalizationElements[i];
                    // remove from dom
                    $articlePersonalizationElements[i].outerHTML = '';
                }

                // Remove element in array
                apBoxes.splice(index, 1);

                // Reset text in first dropdown box
                if(apBoxes.length == 1) {
                    apBoxes[0].querySelector(".dropdown-header--text").innerText = 'Heimatort wählen';
                }

                var allLoaded = false;

                // Render elements from array
                for (var j = 0; j < apBoxes.length; j++) {

                    $parentNode.insertAdjacentHTML('beforeend', apBoxes[j].outerHTML);

                    if (apBoxes[j].classList.contains(articleLoadedClass)) {
                        allLoaded = true;
                    } else {
                        allLoaded = false;
                    }
                }

                if (allLoaded) {
                    $parentNode.insertAdjacentHTML('beforeend', articlePersonalisationHTML);
                }

                updateDropDowns();

                // set c1 property
                myAreas.splice(index, 1);
                //console.log(myAreas);
                //console.log(myAreas.join(","));
                if (myAreas.length) {
                    saveToCookie(myAreas.join(","));
                    saveToProfile(myAreas.join(","));
                } else {
                    saveToCookie(",,");
                    saveToProfile(",,");
                }

                const event_ = new CustomEvent('personalArticleBoxResetted');
                document.dispatchEvent(event_);

                // initialize elements
                articlePersonalizationPlugin('.article-personalisation');
            });
        });
        updateDropDowns();
    });

    const initialized = new CustomEvent('personalArticleBoxSelectedInitialized', {
        bubbles: true
    });

    $articlePersonalizationElements.forEach(function(e) {
        e.dispatchEvent(initialized);
    });

    const removeLocation = function() {

    }
};

document.addEventListener('DOMContentLoaded', function () {
    (function () {
        articlePersonalizationPlugin('.article-personalisation');
    }());
});