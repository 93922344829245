var dropdownPlugin = function () {
    var dropdown = document.querySelectorAll(".dropdown-global");

    [].forEach.call(dropdown, function (el) {

        var dropdownLevel1 = el.querySelector('.dropdown-level-1');
        var dropdownHeader = dropdownLevel1.querySelector('.dropdown-header--text');

        el.querySelectorAll('.dropdown-header').forEach(function (e) {
            e.addEventListener('click', function () {
                if (this.parentNode.classList.contains("dropdown-open")) {
                    this.parentNode.classList.remove("dropdown-open");
                } else {
                    this.parentNode.classList.add("dropdown-open");
                }
            });
        });

        el.querySelectorAll('.dropdown-item').forEach(function (e) {

            if (e.getAttribute('data-selected') === 'true') {
                dropdownHeader.innerText = e.innerText;
            }

            e.addEventListener('click', function (e) {
                e.preventDefault();
                dropdownHeader.innerText = this.innerText;
                var dropdownOpen = el.querySelectorAll('.dropdown-open');
                [].forEach.call(dropdownOpen, function (thisDropdownOpen) {
                    thisDropdownOpen.classList.remove('dropdown-open');
                });
            });
        });
    });
};

dropdownPlugin();