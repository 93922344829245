var loadingAnimation = (function () {

    var buttonText = '';
    var buttonWidth = 0;
    var loadingClass = 'loading';
    var disabledClass = 'button--disabled';

    var loaderEllipsisHTML = '<i class="loader--ellipsis ' + loadingClass + '">' +
        '<i class="loader--ellipsis-item"></i>' +
        '<i class="loader--ellipsis-item"></i>' +
        '<i class="loader--ellipsis-item"></i>' +
        '<i class="loader--ellipsis-item"></i>' +
        '</i>';

    return {
        addAnimation: function (obj) {
            buttonText = obj.innerText;
            buttonWidth = obj.offsetWidth;
            obj.classList.add(loadingClass);
            obj.classList.add(disabledClass);
            obj.style.width = buttonWidth + 'px';
            obj.setAttribute('disabled', 'disabled');
            obj.innerText = '';
            obj.innerHTML = loaderEllipsisHTML;
        },
        removeAnimation: function (obj, passedButtonText='') {
            obj.innerText = (passedButtonText ? passedButtonText : buttonText);
            obj.removeAttribute('disabled');
            obj.removeAttribute('style');
            obj.classList.remove(loadingClass);
            obj.classList.remove(disabledClass);
        }
    };
})();

// FOR TESTING ONLY
// var buttons = document.querySelectorAll('.button--submit');
// [].forEach.call(buttons, function (button) {
//
//     button.addEventListener('click', function () {
//         loadingAnimation.addAnimation(button);
//
//         setTimeout(function () {
//             loadingAnimation.removeAnimation(button);
//         },2000);
//     });
// });