(function (){
    const sectionHeading = document.querySelectorAll('.section-heading');

    [].forEach.call(sectionHeading, function (thisSectionHeading) {
        const sectionHeadingLinks = thisSectionHeading.querySelectorAll('.section-heading__link--self').forEach(function (thisSectionHeadingLink) {
            thisSectionHeadingLink.addEventListener('click', function (e) {
                e.preventDefault();
                const id = this.getAttribute("href").slice(1);
                scrollToTarget(id);
            });
        });
    });

    let scrollToTarget = function (id) {
        const targetElement = document.getElementById(id);
        targetElement.scrollIntoView({ behavior: 'smooth' });
    };
}());