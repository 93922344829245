document.addEventListener('DOMContentLoaded', function() {
   window.addEventListener('message', function(e) {
      if (e.origin === 'https://ticker.noz.de') {
         var frames = document.getElementsByTagName('iframe');
         for (var i = 0; i < frames.length; i++) {
            if (frames[i].contentWindow === e.source) {
               frames[i].setAttribute('height', e.data.height);
            }
         }
      }
   });
});