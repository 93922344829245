document.addEventListener("DOMContentLoaded", function() {
    var overlay = document.querySelector('.overlay');
    var deviceList = document.querySelector('.device-limit-modal__devices');
    var info = document.querySelector('.device-limit-modal__info');
    var removed = document.querySelector('.device-limit-modal__removed');
    var limitHeadline = document.querySelector('.act-devices-headline');

    document.querySelectorAll('.device-limit-modal__device__remove-btn').forEach(function(e) {
        e.addEventListener('click', function(e) {
            // call api to remove device from list for current user

            deviceList.remove();
            limitHeadline.remove();
            info.remove();
            removed.classList.remove('d-none');
            window.setTimeout(function() {
                overlay.remove();
            }, 5000);
        });
    });
});


