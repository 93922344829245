(function() {
    let restoreLinks = function(parent) {
        if (parent) {
            let links = parent.querySelectorAll('.link')
            links.forEach((link) => {
                let classes = (link.classList.toString().split(' ')).filter(n => n);
                let url = link.dataset.url;
                let text = link.innerText;
                let a = document.createElement('a');
                a.innerText = text;
                a.href = url;
                a.dataset.lid = link.dataset.lid;
                a.classList.add(...classes);
                link.replaceWith(a);
            });
        }
    }

    let init = function(event) {
        let switchBox = document.querySelector('.switch-box__switch');
        restoreLinks(switchBox);
        let links = switchBox?.querySelectorAll('a');
        let border = switchBox?.querySelector('.border-decoration');
        let mainContent = document.querySelector('main.container.content');
        let mainContentPosition = '';
        let breadCrumbClone = null;

        let setBorder = function(link, border) {
            removeActive();
            link.classList.add('active');
            var lVal = link.getBoundingClientRect();
            var pos = Math.round(lVal.x - parent.x);
            var width = Math.round(lVal.width);
            border.style.left = pos + 'px';
            border.style.width = width + 'px';
        };

        let removeActive = function() {
            links.forEach(function(l) {
                l.classList.remove('active');
            });
        };

        let fetchContent = function(link) {
            if (!link.classList.contains('active')) {
                setBorder(link, border);
                window.setTimeout(function(){
                let url = link.getAttribute('href');
                if (url) {
                    let resp = window.nozmhnHelper.ajaxFetch(
                        {url: url, page: null, jsonResp: true},
                    );

                    if (resp.content) {
                        if (resp.ids) {
                            loadedArticles = resp.ids;
                        }
                        mainContent.innerHTML = resp.content;
                        mainContent.getElementsByTagName('script').forEach(function(s) {
                           var script = document.createElement('script');
                           if (s.src != "") {
                               script.src = s.src;
                           } else {
                               script.text = s.text;
                           }
                            mainContent.appendChild(script);
                        });
                        triggerDomLoadedEvent();
                        restoreBreadCrumb();
                        init();
                    }
                }
                }, 500);
            }
        };

        let setLocationFromCookie = function() {
            let loc = Cookies.get('COOKIE_NAME'); // 0 or 1
            if (loc) {
                if (!links[loc].classList.contains('active')) {
                    setBorder((loc === 0 ? 1 : 1));
                }
            }
        };

        let toggleOverlay = function() {
            let overlay = mainContent.querySelector('.main-content__overlay');
            if (overlay) {
                overlay.remove();
                mainContent.style.position = mainContentPosition;
            } else {
                let mainContentPosition = mainContent.style.position;
                mainContent.style.position = 'relative';
                let overlay = document.createElement('div');
                overlay.classList.add('main-content__overlay');
                mainContent.insertAdjacentElement('afterbegin', overlay);
            }
        }

        let storeBreadCrumb = function() {
            let breadCrumb = document.querySelector('.misc');
            if (breadCrumb) {
                breadCrumbClone = breadCrumb.cloneNode(true);
            }
        }

        let restoreBreadCrumb = function() {
            if (breadCrumbClone) {
                document.querySelector('main.content').insertAdjacentElement('afterbegin', breadCrumbClone);
            }
        }

        let triggerDomLoadedEvent = function() {
            window.document.dispatchEvent(new Event("DOMContentLoaded", {
                bubbles: true,
                cancelable: true
            }));
        }

        const saveToCookie = (link) => {
            const expirationDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365).toUTCString();
            document.cookie = `_pc_regionswitch=${link.dataset.lid}; expires=${expirationDate}; path=/`;
        }

        const saveToProfile = link => {
            if (tp?.pianoId?.isUserValid()) {
                fetch(`${
                    nozmhnHelper.environment() == 'prd' ? 'https://api-eu.piano.io' : 'https://sandbox.piano.io'
                }/id/api/v1/identity/userinfo?aid=${tp.aid}&access_token=${tp.pianoId.getToken()}&lang=de_DE`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json; charset=UTF-8' },
                    body: JSON.stringify({
                        uid: tp.pianoId.getUser().uid,
                        form_name: 'switch-box',
                        custom_field_values: [{
                            field_name: 'portalRegionSwitch',
                            value: `["${link.dataset.lid}"]`,
                        }],
                    }),
                });
            }
        }

        if (links && links.length >= 2) {
            setLocationFromCookie();
            var parent = switchBox.getBoundingClientRect();
            links = Array.prototype.slice.call(links);
            var activeLink = links.filter(function(l) {
                return l.classList.contains('active');
            })[0];
            if (activeLink) {
                setBorder(activeLink, border);
            }

            links.forEach(function(l) {
                l.addEventListener('click', function(e) {
                    e.preventDefault();
                    if (!e.target.classList.contains('active')) {
                        storeBreadCrumb();
                        toggleOverlay();
                        // setBorder(l, border);
                        saveToCookie(l);
                        saveToProfile(l);
                        fetchContent(l);
                    }
                });
            });
        }
    };

    //document.addEventListener('DOMContentLoaded', init);
    init();
})();