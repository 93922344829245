document.addEventListener('DOMContentLoaded', function() {
    var positionBorder = function({border, posEl}) {
        const elMeasures = posEl.getBoundingClientRect();
        if (elMeasures) {
            const left = posEl.offsetLeft;
            const width = Math.floor(elMeasures['width']);
            border.style.left = left+'px';
            border.style.width = width+'px';
        }
    };

    var setDayVisible = function({parent, day}) {
        if (!day.classList.contains('active')) {
            const currIndex = day.dataset.index;
            const dayDivEls = parent.querySelectorAll('.horoscope-widget__day');
            const dayEls = parent.querySelectorAll('.horoscope-widget__nav--day');
            dayEls.forEach(function(d) {
                d.classList.remove('active');
            });

            dayDivEls.forEach(function(dayDiv, idx) {
                dayDiv.classList.remove('active');
                if (idx == currIndex) {
                    day.classList.add('active');
                    dayDiv.classList.add('active');
                }
            });
        }
    };

    const horoscopes = document.querySelector('.horoscope-widget');
    if (horoscopes) {
        const nav = horoscopes.querySelector('.horoscope-widget__nav');
        const deco = nav.querySelector('.border-decoration');
        const active = nav.querySelector('.active');
        const navItems = nav.querySelectorAll('.horoscope-widget__nav--day');
        positionBorder({border: deco, posEl: active});

        navItems.forEach(function(a) {
           a.addEventListener('click', function(e) {
               setDayVisible({parent: horoscopes, day: e.target})
               positionBorder({border: deco, posEl: e.target});
           })
        });
    }
});
