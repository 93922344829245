function makePodcastTitleScrollable(podcastElement) {
    const maxDescriptionLength = 125;
    const podcasts = [podcastElement];

    if (!nozmhnHelper.isMobile() && podcasts.length >= 1) {
        podcasts.forEach((podcast) => {
            let description = podcast.querySelector('.noz-description');
            if (description && description.innerText.length > maxDescriptionLength) {
                description.classList.add('shrink');
            }
        });
    }

    // Animate titles
    function displayTextWidth(text, font) {
        let canvas = displayTextWidth.canvas ||
            (displayTextWidth.canvas = document.createElement('canvas'));
        let context = canvas.getContext('2d');
        context.font = font;
        let metrics = context.measureText(text);

        return metrics.width;
    }

    podcastElement.querySelectorAll('div[data-role="sdn-audio-player"] div[data-role="title"]').
        forEach((titleContainer) => {
            titleContainer.style.textOverflow = "initial";
            let containerWidth = titleContainer.getBoundingClientRect().width;
            let title = titleContainer.querySelector('.sdn-ellipsis');
            let d = document.createElement('div');
            d.classList.add('scroller');
            d.innerText = title.firstChild.nodeValue;
            title.firstChild.replaceWith(d);
            let titleWidth = displayTextWidth(title.firstChild.innerText,
                window.getComputedStyle(title).font);
            let scrollWidth = Math.round(containerWidth - titleWidth);
            title.querySelector('.scroller').setAttribute('data-scroll', scrollWidth);
        });

    podcastElement.querySelectorAll('.scroller').forEach((s) => {
        s.addEventListener('mouseover', () => {
            s.style.left = s.dataset.scroll + 'px';
        });
        s.addEventListener('mouseout', () => {
            s.style.left = '0px';
        });
    });
};