document.addEventListener('DOMContentLoaded', function() {
    const buttons = document.querySelectorAll('.comment-button');

    buttons.forEach(function (b) {
        let container = b;
        while(container !== document.body){
            if(container.getAttribute("class") && container.getAttribute("class").indexOf("comment-container") !== -1){
                break;
            }
            container = container.parentElement;
        }

        b.addEventListener('click', function(event){
            try {
                const resp = window.nozmhnHelper.ajaxFetch({
                    url: "/engagently/token",
                    page: null,
                    jsonResp: true,
                    method: 'POST',
                    withCredentials: true
                });

                if(resp.token && resp.token.trim() !== "" && window.engagently && window.engagently.signIn){
                    window.engagently.signIn({ token: resp.token });
                }else{
                    throw Error("failed to fetch token");
                }
            } catch (error) {
                try {
                    const resp = window.nozmhnHelper.ajaxFetch({
                        url: "/engagently/token",
                        page: null,
                        jsonResp: true,
                        method: 'POST',
                        withCredentials: true
                    });
    
                    if(resp.token && resp.token.trim() !== "" && window.engagently && window.engagently.signIn){
                        window.engagently.signIn({ token: resp.token });
                    }else{
                        throw Error("failed to fetch token");
                    }
                } catch (error) {
                    window.engagently.signIn({ token: "" });
                }
            }

            let engagently = document.createElement("egy-discussion");
            engagently.setAttribute("discussion-id",event.target.getAttribute("discussion-id"));
            engagently.setAttribute("discussion-title",event.target.getAttribute("discussion-title"));
            engagently.setAttribute("signature",event.target.getAttribute("signature"));
            engagently.setAttribute("url",event.target.getAttribute("url"));
            engagently.setAttribute("image-url",event.target.getAttribute("image-url"));

            let callToAction = document.createElement("div");
            callToAction.setAttribute("slot", "customCallToAction");
            let anonymousMessage = document.createElement("div");
            anonymousMessage.setAttribute("id", "anonymousMessage");
            anonymousMessage.setAttribute("style", "display: none;");
            let anonymousMessageText = document.createElement("span");
            anonymousMessageText.textContent = "Werden Sie selbst Teil der Community und lesen Sie alle Kommentare.";
            anonymousMessageText.setAttribute("class", "engagently--noAccessMessage__text");
            let anonymousMessageButton = document.createElement("a");
            anonymousMessageButton.setAttribute("href", window.globalNotLoggedInURL);
            anonymousMessageButton.textContent = "Hier anmelden";
            anonymousMessageButton.setAttribute("class", "engagently--noAccessMessage__button");
            let anonymousMessageWrapper = document.createElement("div");
            anonymousMessageWrapper.setAttribute("class", "engagently--noAccessMessage");
            anonymousMessageWrapper.appendChild(anonymousMessageText);
            anonymousMessageWrapper.appendChild(anonymousMessageButton);
            anonymousMessage.appendChild(anonymousMessageWrapper);
            callToAction.appendChild(anonymousMessage);
            let notPaidMessage = document.createElement("div");
            notPaidMessage.setAttribute("id", "notPaidMessage");
            notPaidMessage.setAttribute("style", "display: none;");
            let notPaidMessageText = document.createElement("span");
            notPaidMessageText.textContent = "Sie wollen mitreden? Jetzt Abo abschließen und sofort eigene Kommentare schreiben.";
            notPaidMessageText.setAttribute("class", "engagently--noAccessMessage__text");
            let notPaidMessageButton = document.createElement("a");
            notPaidMessageButton.setAttribute("href", window.globalNotPaidURL);
            notPaidMessageButton.textContent = "Jetzt Angebot sichern";
            notPaidMessageButton.setAttribute("class", "engagently--noAccessMessage__button");
            let notPaidMessageWrapper = document.createElement("div");
            notPaidMessageWrapper.setAttribute("class", "engagently--noAccessMessage");
            notPaidMessageWrapper.appendChild(notPaidMessageText);
            notPaidMessageWrapper.appendChild(notPaidMessageButton);
            notPaidMessage.appendChild(notPaidMessageWrapper);
            callToAction.appendChild(notPaidMessage);
            engagently.appendChild(callToAction);

            let likeIcon = document.createElement("svg");
            likeIcon.setAttribute("slot", "reaction1");
            likeIcon.setAttribute("fill", "rgb(127, 127, 127)");
            likeIcon.setAttribute("xmlns", "http://www.w3.org/2000/svg");
            likeIcon.setAttribute("height", "16");
            likeIcon.setAttribute("width", "16");
            likeIcon.setAttributeNS(null, "viewBox", "0 0 512 512");
            let likeIconPath = document.createElement("path");
            likeIconPath.setAttribute("d", "M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z")
            likeIcon.appendChild(likeIconPath);
            engagently.appendChild(likeIcon);
            let likeIconActive = document.createElement("svg");
            likeIconActive.setAttribute("slot", "reaction1_active");
            likeIconActive.setAttribute("fill", "rgb(127, 127, 127)");
            likeIconActive.setAttribute("xmlns", "http://www.w3.org/2000/svg");
            likeIconActive.setAttribute("height", "16");
            likeIconActive.setAttribute("width", "16");
            likeIconActive.setAttributeNS(null, "viewBox", "0 0 512 512");
            let likeIconActivePath = document.createElement("path");
            likeIconActivePath.setAttribute("d", "M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z")
            likeIconActive.appendChild(likeIconActivePath);
            engagently.appendChild(likeIconActive);

            container.replaceWith(engagently);
        });
    });    
});