document.addEventListener('DOMContentLoaded', function() {
    var dayNaming = {
        'today': 'Heute',
        'tomorrow': 'Morgen',
    };

    /**
     * Initialize the event slider
     * @param slider
     * @param apiEndpoint
     * @param minEntries
     * @param maxPerDay
     * @param maxEntryAge
     * @constructor
     */
    var InitEventSlider = function({
                                       slider,
                                       apiEndpoint,
                                       minEntries,
                                       maxPerDay,
                                       maxEntryAge,
                                   }) {
        fetchData({
            apiEndpoint: apiEndpoint,
            slider: slider,
            minEntries: minEntries,
            maxPerDay: maxPerDay,
        });
    }

    /**
     * Get the html from the response
     * @param resp
     * @param maxPerDay
     * @param elementTemplate
     * @returns {DocumentFragment}
     */
    var getHtmlFromResponse = function({
                                           resp,
                                           maxPerDay = 10,
                                           elementTemplate,
                                       }) {
        var frag = document.createDocumentFragment();
        var countEntries = 0;

        if (resp && elementTemplate) {
            if (resp.today) {
                resp.today.forEach(function(entry, key) {
                    var isFirst = key === 0;
                        frag.appendChild(
                            prepareHtmlForEntry({
                                elementTemplate,
                                data: entry,
                                first: isFirst,
                                day: 'today',
                            }),
                        );
                        countEntries++;
                });
            }

            if (countEntries < maxPerDay) {
                var i = 0;
                while (countEntries <= maxPerDay && i < resp.tomorrow.length) {
                    var isFirst = i === 0;
                    if (resp.tomorrow[i].picture !== "" && resp.tomorrow[i].headline !== "") {
                        frag.appendChild(
                            prepareHtmlForEntry({
                                elementTemplate,
                                data: resp.tomorrow[i],
                                first: isFirst,
                                day: 'tomorrow',
                            }),
                        );
                        countEntries++;
                    }
                    i++;
                }
            }
        }

        return frag;
    };

    /**
     * Build the html for a single slider entry
     * @param elementTemplate
     * @param data
     * @param first
     * @param day
     * @returns {ActiveX.IXMLDOMNode | Node}
     */
    var prepareHtmlForEntry = function({elementTemplate, data, first, day}) {
        var clonedElement = elementTemplate.cloneNode(true);
        var link = document.createElement('a');
        if (day === 'tomorrow' && first) {
            clonedElement.firstElementChild.parentNode.classList.add('spacer');
        }
        link.classList.add('link', 'intern', 'text--reset');
        link.href = data.url;
        link.alt = data.title;
        link.target = '_blank';

        if (!first) {
            clonedElement.querySelectorAll('.first, .loading').
                forEach(function(el) {
                    el.classList.remove('first', 'loading');
                });
            clonedElement.querySelector('.date-line__day').remove();
        } else {
            clonedElement.querySelectorAll('.loading').
                forEach(function(el) {
                    el.classList.remove('loading');
                });
            clonedElement.querySelector(
                '.date-line__day').textContent = dayNaming[day];
        }

        var imgLink = link.cloneNode();
        var imgPlaceholder = clonedElement.querySelector(
            '.slider__element__img-placeholder');
        imgPlaceholder.classList.remove('slider__element__img-placeholder');
        var img = document.createElement('img');
        img.classList.add('slider__element__img');
        img.src = data.picture;
        img.alt = data.title;
        img.title = data.title;
        imgLink.appendChild(img);
        imgPlaceholder.replaceWith(imgLink);

        var subline = link.cloneNode();
        subline.textContent = data.headline;
        clonedElement.querySelector('.slider__element__subline').
            appendChild(subline);

        var headlineLink = link.cloneNode();
        headlineLink.textContent = data.title;
        clonedElement.querySelector('.slider__element__headline').
            appendChild(headlineLink);

        var infoLink = link.cloneNode();
        infoLink.textContent = data.startTime + ' Uhr';
        clonedElement.querySelector('.slider__element__info').
            appendChild(infoLink);

        return clonedElement;
    };

    /**
     * Trigger an update in order to show the next button
     * @param sliderElements
     * @param timeout
     */
    var triggerSliderBoxUpdate = function({sliderElements, timeout}) {
        window.setTimeout(function() {
            sliderElements.scroll(200, 0);
            sliderElements.scroll(0, 0);
        }, timeout);
    };

    /**
     * Replace the dummy entries with real content
     * @param slider
     * @param html
     * @returns {string}
     */
    var replaceSliderBox = function({slider, html}) {
        var appendedHtml = '';
        var isDocFragment = true;
        if (html.constructor !== DocumentFragment) {
            isDocFragment = false;
        }
        var prevButton = slider.querySelector('.prev-bubble');
        var nextButton = slider.querySelector('.next-bubble');
        var sliderElements = slider.querySelector('.slider__elements');
        slider.classList.remove('loading');
        if (isDocFragment) {
            sliderElements.innerHTML = '';
            sliderElements.appendChild(html);
            appendedHtml = sliderElements.innerHTML;
        } else {
            sliderElements.innerHTML = html;
        }
        sliderElements.appendChild(prevButton);
        sliderElements.appendChild(nextButton);
        triggerSliderBoxUpdate({sliderElements: sliderElements, timeout: 100});

        return appendedHtml;
    };

    /**
     * Check if there are enough entries to show
     * @param resp
     * @param minEntries
     * @returns {boolean}
     */
    var hasEnoughEntries = function(resp, minEntries) {
        var hasEnough = false;

        if ((resp.today.length + resp.tomorrow.length) >= minEntries) {
            hasEnough = true;
        }

        return hasEnough;
    };

    /**
     * Remove the entire box
     * @param sliderBox
     */
    var removeBox = function(sliderBox) {
        if (!sliderBox.classList.contains('slider-box')) {
            sliderBox = sliderBox.closest('.slider-box');
        }
        sliderBox.remove();
    };

    /**
     * Fetch the data from remote
     * @param apiEndpoint
     * @param slider
     * @param minEntries
     * @param maxPerDay
     * @param location
     * @param category
     */
    var fetchData = function({
                                 apiEndpoint,
                                 slider,
                                 minEntries,
                                 maxPerDay,
                             }) {
        fetch(apiEndpoint, {
            method: 'GET',
        }).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (hasEnoughEntries(data, minEntries)) {
                var fragment = getHtmlFromResponse({
                    resp: data,
                    maxPerDay: maxPerDay,
                    elementTemplate: slider.querySelector(
                        '.slider__elements .events-wrapper',
                    ),
                });
                if (fragment) {
                    var html = replaceSliderBox(
                        {slider: slider, html: fragment});
                }
            } else {
                removeBox(slider);
            }
        }).catch(function(error) {
            removeBox(slider);
        });
    };

    document.querySelectorAll('.slider.loading').forEach(function(slider) {
        var sliderBox = slider.closest('.slider-box');
        var endpoint = sliderBox.dataset.apiurl;
        var minEntries = sliderBox.dataset.min;
        var maxPerDay = sliderBox.dataset.maxDay;
        var maxEntryAge = 1000 * 60 * 15;

        InitEventSlider({
            slider: slider,
            apiEndpoint: endpoint,
            minEntries: minEntries,
            maxPerDay: maxPerDay,
            maxEntryAge: maxEntryAge,
        });
    });
});

