document.addEventListener('DOMContentLoaded', function() {
    init();

    function setListener(button, url) {
        button.addEventListener('click', function(e) {
            e.preventDefault();
            const parent = e.target.closest('.author-reload');
            fetchArticles({url: url, parent: parent});
        });
    }

    function fetchArticles({url, parent}) {
        let response = window.nozmhnHelper.ajaxFetch(
            {url: url},
        );

        if (response) {
            const parser = new DOMParser();
            const result = parser.parseFromString(response, 'text/html');
            parent.classList.remove('author-reload');
            parent.innerHTML = result.body.innerHTML;

            const button = document.querySelector('.button-reload-author');
            setListener(button, button.dataset.url);
        } else {
            const button = document.querySelector('.button-reload-author');
            button.remove();
        }
    }

    function init() {
        var reloadButton = document.querySelector('.button-reload-author');

        if (reloadButton) {
            const url = reloadButton.dataset.url;
            setListener(reloadButton, url);
        }
    }
});