window.nozmhnHelper = {
    ajaxFetch: function({
                            url,
                            page = null,
                            jsonResp = false,
                            method = 'GET',
                            obj = {},
                            payload = '',
                            withCredentials = false,
                            contentType = '',
                        }) {
        let response = '';
        let xhr = new XMLHttpRequest();
        let body = payload !== '' ? payload : null;
        if (withCredentials) {
            xhr.withCredentials = true;
        }
        body = typeof body === 'object' ? JSON.stringify(body) : body;

        xhr.addEventListener('load', function() {
            response = this.responseText;
        });
        xhr.open(method, url, false);
        // xhr.setRequestHeader('Authorization', 'Basic ' + enc);
        if (contentType !== '') {
            xhr.setRequestHeader('Content-type', contentType);
        }
        xhr.send(body);

        if (response && jsonResp) {
            response = JSON.parse(response);
        }

        return response;
    },

    isIos: function isIos() {
        let isIosAgent = navigator.userAgent.match(/iPhone|iPad|iPod/i);
        let isSafari = navigator.userAgent.match(/Safari/);
        let hasTouchPoints = navigator.maxTouchPoints > 0;

        return isIosAgent || isSafari && hasTouchPoints;
    },

    isAndroid: function() {
        return navigator.userAgent.match(/Android/i);
    },

    isMobile: function() {
        return window.matchMedia('(max-width: 30.06em').matches;
    },

    tabletView: function () {
        var width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        return width > 480 ? true : false;
    },

    desktopView: function () {
        var width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        return width > 768 ? true : false;
    },

    isMobileDevice: function(isCheckBrowserClient) {
        if (isCheckBrowserClient && (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i))
        ) {
            return true;
        }
        return false;
    },

    environment: () => {
        switch (location.hostname.split('.').pop()) {
            case 'localhost': return 'local';
            case 'works': return 'stg';
            case 'de': return 'prd';
            default: return 'unknown';
        }
    }
};