(function () {
    let smartbannerMeta = document.querySelector("meta[name='smartbanner:api']");
    if(smartbannerMeta !== null) {
        var header = document.querySelector('.main-header');
        if (header !== null) {
            if (nozmhnHelper.isAndroid() && Cookies.get('smartbanner_exited') === undefined) {
                if (Cookies.get('smartbanner') === undefined) {
                    Cookies.set('smartbanner', 1);
                } else {
                    var html = document.querySelector('html');
                    var body = document.querySelector('body');
                    body.insertAdjacentHTML('afterbegin', '<div class="smartbanner--holder" style="position: relative; height: 70px;"></div>');
                    html.style.marginTop = '0';
                    html.classList.add('smartbanner--reset-margin');
                    let apiHandler = function () {
                        smartbanner.publish();
                        document.addEventListener('smartbanner.view', function () {
                            var smartBannerHTML = document.querySelector('.smartbanner').cloneNode(true);
                            document.querySelector('.smartbanner').outerHTML = '';
                            document.querySelector('.smartbanner--holder').insertAdjacentHTML('afterbegin', smartBannerHTML.outerHTML);
                        });
                        document.addEventListener('smartbanner.exit', function () {
                            document.querySelector('.smartbanner--holder').outerHTML = '';
                        });
                    };
                    window.onload = apiHandler;
                }
            }
        }
    }
}());