let initAutoComplete = function() {
    var selector = 'autoComplete';
    var autocomplete = document.querySelector('#' + selector);

    var processInitialisation = function() {
        if (autocomplete !== null) {
            autocomplete.addEventListener('boxUpdated', initAutoComplete);
            var externalSource = autocomplete.dataset.source;

            if (externalSource !== '') {
                var name = document.querySelector('#' + selector).dataset.name;

                // The autoComplete.js Engine instance creator
                var autoCompleteJS = new autoComplete({
                    name: name,
                    data: {
                        src: JSON.parse(externalSource),
                        key: ['Ort/Stadt', 'Postleitzahl'],
                        results: function results(list) {
                            // Filter duplicates
                            var filteredResults = Array.from(
                                new Set(list.map(function(value) {
                                    return value.match;
                                }))).map(function(food) {
                                return list.find(function(value) {
                                    return value.match === food;
                                });
                            });

                            return filteredResults;
                        },
                    },
                    trigger: {
                        event: ['input', 'focus'],
                    },
                    placeHolder: 'Ort suchen',
                    searchEngine: 'strict',
                    highlight: true,
                    maxResults: 5,
                    resultItem: {
                        content: function content(data, element) {
                            // Modify Results Item Content
                            if (data.key === 'Postleitzahl') {
                                element.innerHTML = data.value['Ort/Stadt'] + ', ' +
                                    data.match;
                            } else {
                                element.innerHTML = data.match + ', ' +
                                    data.value.Postleitzahl;
                            }
                        },
                    },
                    noResults: function noResults(dataFeedback, generateList) {
                        // Generate autoComplete List
                        generateList(autoCompleteJS, dataFeedback,
                            dataFeedback.results);
                        // No Results List Item
                        var result = document.createElement('li');
                        result.setAttribute('class', 'autoComplete_no_result');
                        result.setAttribute('tabindex', '1');
                        result.innerHTML = '<span>Keine Ergebnisse für ' +
                            dataFeedback.query + ' gefunden</span>';
                        document.querySelector(
                            '#' + autoCompleteJS.resultsList.idName).
                            appendChild(result);
                    },
                    onSelection: function onSelection(feedback) {
                        var autoComplete = document.querySelector('#' + selector);
                        autoComplete.blur();
                        autoComplete.value = feedback.selection.value['Ort/Stadt'];

                        autoComplete.closest('.weather-box').
                            querySelector(
                                '.weather-box__header .name').innerHTML = autoComplete.value;
                        Cookies.set('weather_location', autoComplete.value);
                        const locSelectedEvent = new CustomEvent(
                            'weatherLocationSelected', {
                                detail: {
                                    id: feedback.selection.value['Postleitzahl'],
                                },
                            });
                        autoComplete.dispatchEvent(locSelectedEvent);
                    },
                });
            }
        }
    }

    if (autocomplete && typeof autoComplete != 'function') {
        let autoScript = document.createElement('script');
        autoScript.src = location.origin + '/static/autocomplete.min.js';
        autoScript.async = false;
        document.querySelector('head').insertAdjacentElement('beforeend', autoScript);
        autoScript.addEventListener('load', () => {
            processInitialisation();
        });
    } else {
        processInitialisation();
    }
};

document.addEventListener('DOMContentLoaded', initAutoComplete);