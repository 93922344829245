var foldableContentFn = function () {
    var foldableContent = document.querySelectorAll(".foldable-content");
    [].forEach.call(foldableContent, function (el) {
        var foldableContentClick = el.querySelector('.foldable-content__click');
        var foldableContentRead = el.querySelector('.foldable-content__read');

        foldableContentClick.addEventListener('click', function () {
            if (el.classList.contains("foldable-content--open")) {
                el.classList.remove("foldable-content--open");
                foldableContentRead.innerText = 'Mehr lesen';
            } else {
                el.classList.add("foldable-content--open");
                foldableContentRead.innerText = 'Weniger lesen';
            }
        });
    });
};

foldableContentFn();