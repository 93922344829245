document.addEventListener('DOMContentLoaded', function() {
    var doiMessages = document.querySelectorAll('.doi-action');
    if (doiMessages.length > 0) {
        [].forEach.call(doiMessages, function (doiMessage) {
            var apiLink = doiMessage.querySelector('a');
            var resp = '';
            var location = window.location.href;
            apiLink.addEventListener('click', function(e) {
                e.preventDefault();
                loadingAnimation.addAnimation(doiMessage);
                resp = nozmhnHelper.ajaxFetch({
                    url: apiLink.href,
                    page: null,
                    jsonResp: true,
                    method: 'POST',
                    payload: {"ref": location},
                    withCredentials: true,
                    contentType: 'application/json',
                });
                if (resp.status === 'ok' || true) {
                    loadingAnimation.removeAnimation(doiMessage, "Es wurde eine E-Mail an Sie versendet. Bitte bestätigen Sie Ihre Registrierung per Klick auf den Link in der E-Mail.");
                    //doiMessage.innerText = "Es wurde eine E-Mail an Sie versendet. Bitte bestätigen Sie Ihre Registrierung per Klick auf den Link in der E-Mail.";
                    apiLink.remove();
                }
            });
        });
    }
});
