var scheduleBoxPlugin = function () {
    var scheduleBox = document.querySelectorAll(".league-schedule-box");

    [].forEach.call(scheduleBox, function (el) {
        var dropdownItems = el.querySelectorAll('.dropdown-item');

        [].forEach.call(dropdownItems, function (dropdownItem) {
            dropdownItem.addEventListener('click', function (e) {
                e.preventDefault();
                var scheduleBoxDay = e.target.closest('.league-schedule-box').querySelectorAll('.league-schedule-box-day');
                for (var i = 0; i < scheduleBoxDay.length; i++) {
                    scheduleBoxDay[i].classList.add('hidden');
                }
                document.querySelector(dropdownItem.getAttribute('href')).classList.remove("hidden");
            });
        });
    });
};

scheduleBoxPlugin();