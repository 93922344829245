document.addEventListener('DOMContentLoaded', function() {
    var reloadButtons = document.querySelectorAll('.button-reload');

    if (reloadButtons.length > 0) {
        reloadButtons.forEach(function(button) {
            const url = button.dataset.url + '&dspage=';
            const maxArticlesToInsert = Number(button.dataset.show);
            let noMoreArticles = false;
            let page = 0;
            let listContainer = getContainerForButton(button);
            let currLoadedArticles = (typeof loadedArticles !== 'undefined' ? loadedArticles : []);
            let buttonText = button.innerText;

            if (url !== '') {
                button.addEventListener('click', function(e) {
                    e.preventDefault();

                    loadingAnimation.addAnimation(button);
                    let loadEvent = new CustomEvent('loadArticles');
                    setTimeout(() => {
                        button.dispatchEvent(loadEvent);
                    }, 10);
                });

                button.addEventListener('loadArticles', function(e) {
                    e.preventDefault();
                    let articlesToInsert = '';
                    let noMoreArticles = false;
                    let articles = getStoredArticles(button);
                    storeArticles({button: button, articles: []});
                    let buttonIndex = getReloadButtonPosition({button: e.target, loadedArticles: currLoadedArticles});
                    let loadedArticlesSlice = getLoadedArticlesSlice({index: buttonIndex, loadedArticles: currLoadedArticles});
                    loadingAnimation.addAnimation(button);
                    let iteration = 0;
                    let returnCount = 0;
                    setTimeout(() => {
                        if (articles) {
                            articles = removeDuplicates({articles: articles, loaded: loadedArticlesSlice});
                        }
                        while (articles.length <= maxArticlesToInsert && !noMoreArticles &&
                        iteration < 5) {
                            let newArticles = fetchArticles(
                                {url: url + page, page: 0, obj: button},
                            );
                            returnCount = newArticles.length;
                            page = ++page;

                            noMoreArticles = newArticles.length === 0;

                            if (newArticles.length > 0) {
                                articles = articles.concat(newArticles);

                                articles = removeDuplicates(
                                    {articles: articles, loaded: (loadedArticlesSlice ? loadedArticlesSlice : currLoadedArticles)});
                            }
                            iteration = (iteration + 1);
                        }

                        if (articles.length > 0) {
                            articlesToInsert = articles.splice(0, maxArticlesToInsert);
                        }

                        if (articlesToInsert) {
                            currLoadedArticles = updateLoadedArticlesList({
                                loadedArticles: currLoadedArticles,
                                newArticles: articlesToInsert,
                                index: (loadedArticlesSlice ? loadedArticlesSlice.length : -1 )
                            });

                            insertArticles({
                                articles: articlesToInsert,
                                listContainer: listContainer,
                            });
                            storeArticles({button: button, articles: articles});
                            loadingAnimation.removeAnimation(button, buttonText);

                            // check if there are more articles to display
                            let nextArticles = fetchArticles(
                                {url: url + page, page: 0, obj: button},
                            );

                            if (articles.length) {
                                nextArticles = articles.concat(nextArticles);
                            }

                            articles = removeDuplicates(
                                {articles: nextArticles, loaded: (loadedArticlesSlice ? loadedArticlesSlice : currLoadedArticles)});
                            page = ++page;
                            storeArticles({button: button, articles: nextArticles, holdOld: true});
                            if (nextArticles.length === 0) {
                                noMoreArticles = true;

                              if ((getStoredArticles(button)).length === 0) {
                                  button.remove();
                              }
                            }
                        } else {
                            button.remove();
                        }
                    }, 0);
                });
            }
        });
    }
});

/**
 * Get the element in witch the loaded articles should be inserted
 * @param element
 * @returns {Node}
 */
let getContainerForButton = function(element) {
    let teaserContainer = null;
    if (element.classList.contains('grid--gutter-d')) {
        if (element.previousSibling) {
            teaserContainer = element.previousSibling;
        }
    } else {
        teaserContainer = element.closest('.grid--gutter-d')?.previousSibling;
    }

    if (teaserContainer &&
        teaserContainer.querySelectorAll('.article__teaser').length <= 0) {
        getContainerForButton(teaserContainer);
    }

    return teaserContainer;
};

let fetchArticles = function({url, page = 0, obj}) {
    let response = window.nozmhnHelper.ajaxFetch(
        {url: url, page: page, jsonResp: true, obj: obj});

    if (response) {
        if (typeof response !== 'object') {
            response = JSON.parse(response);
        }
    }
    return response.items;
};

let storeArticles = function({button, articles = [], holdOld = false}) {
    if (holdOld && button.hasAttribute('data-article-store')) {
        let oldArticles = JSON.parse(button.dataset.articleStore);
        articles = articles.concat(oldArticles);
    }
    button.dataset.articleStore = JSON.stringify(articles);
};

let getStoredArticles = function(button) {
    let storedArticles = [];

    let tmp = button.dataset.articleStore;
    if (tmp !== 'undefined' && tmp !== undefined) {
        storedArticles = JSON.parse(tmp);
    }

    return storedArticles;
};

let removeDuplicates = function({articles, loaded}) {
    if (articles) {
        var filteredArticles = articles.reduce((unique, item) => {
            return unique.some((e) => {
                return e.id === item.id;
            }) ? unique : [...unique, item];
        }, []);

        return filteredArticles.filter(function(art) {
            return !loaded.includes(art.id);
        });
    }
};

let updateLoadedArticlesList = function({loadedArticles, newArticles, index}) {
    newArticles.map(function (art) {
        loadedArticles.splice(index, 0, art.id);
    });

    return loadedArticles;
};

let getGrid = function(listContainer) {
    let tmpGrid = '';
    for (let elem of listContainer.childNodes) {
        if (!elem.nodeName.includes('comment') && elem.querySelector('.article__teaser')) {
            tmpGrid = elem.cloneNode(true);
            tmpGrid.querySelector(
                '.article__teaser, .article__teaser__separator, .article__teaser__separator').
                remove();
        }
    }

    return tmpGrid;
};

let insertArticles = function({articles, listContainer}) {
    let tmpGrid = getGrid(listContainer);
    let fragm = document.createDocumentFragment();

    if (tmpGrid) {
        articles.forEach(function(article) {
            let clone = tmpGrid.cloneNode(true);
            clone.querySelector(
                '.grid--column.flex').innerHTML = article.teaser;
            fragm.appendChild(clone);
        });

        listContainer.appendChild(fragm);
    }
};

let getReloadButtonPosition = function({button, loadedArticles}) {
    let index = null;
    let buttonId = button.dataset.url.match(/&dswidget=(\d*)&/);
    if (buttonId) {
        buttonId = buttonId[1];
        index = loadedArticles.indexOf('reloading-button-' + buttonId);
    }

    return index;
};

let getLoadedArticlesSlice = function({index, loadedArticles}) {
    if (index != null && index) {
        loadedArticles = loadedArticles.slice(0, index);
    }

    return loadedArticles;
}
