var debounce = function (fn) {
    var frame;

    return function (...params) {
        if (frame) {
            cancelAnimationFrame(frame);
        }

        frame = requestAnimationFrame(function() {
            fn(...params);
        });
    };
};

var initWeatherBox = function() {
    let location = false;
    let weatherBoxes = document.querySelectorAll('.weather-box');
    let weatherBoxSlider = document.querySelector('.weather-box__day-slider');
    let weatherBoxSliderWrapper = document.querySelector('.weather-box__day-slider--wrapper');
    let weatherBoxWarnings = document.querySelectorAll('.weather-box__warnings');

    // let search = window.location.search.substr(1);
    // if (search) {
    //     location = getLocationFromSearch(search);
    // }

    let setShadow = function(element) {
        if (element) {
            if (element instanceof Event) {
                element = element.target;
            }

            if (element.scrollLeft > 0) {
                weatherBoxSlider.classList.add('has-prev');
            } else {
                weatherBoxSlider.classList.remove('has-prev');
            }

            if (element.scrollLeft <
                (element.scrollWidth - element.getBoundingClientRect().width)) {
                weatherBoxSlider.classList.add('has-next');
            } else {
                weatherBoxSlider.classList.remove('has-next');
            }
        }
    }

    setShadow(weatherBoxSliderWrapper);
    if (weatherBoxSliderWrapper) {
        weatherBoxSliderWrapper.addEventListener('scroll', debounce(setShadow));
    }

    if (weatherBoxes.length > 0) {
        if (location) {
            loadNewContent(location);
        }
        weatherBoxes.forEach(function(box) {
            box.addEventListener('boxUpdated', initWeatherBox);
            const autocomplete = box.querySelector('#autoComplete');
            let api = box.dataset.api;
            autocomplete.addEventListener('weatherLocationSelected', function(e) {
                if (api.trim() !== '') {
                    api += '&dsplz=' + e.detail.id;
                    let resp = nozmhnHelper.ajaxFetch({url: api, jsonResp: false});
                    box.outerHTML = resp;
                    setFirstAsActive(box);

                    const boxUpdated = new CustomEvent('boxUpdated', {
                        detail: true
                    });

                    box.dispatchEvent(boxUpdated);
                    autocomplete.dispatchEvent(boxUpdated);
                }
            });

            let tiles = box.querySelectorAll(
                '.weather-box__day-slider .weather-box__day-slider__tile');
            tiles.forEach(function(tile) {
                tile.addEventListener('click', function(t) {
                    t = t.target;
                    if (!t.classList.contains(
                        'weather-box__day-slider__tile')) {
                        t = t.closest('.weather-box__day-slider__tile');
                    }
                    resetState(box);
                    showContent(box, t, t.dataset.tab);
                });
            });
        });
    }

    let showContent = function(box, tile, tabId) {
        tile.classList.add('active');
        box.querySelector(`#${tabId}`).classList.add('active');

        weatherBoxWarnings.forEach(ele => {
            if (ele.classList.contains(`${tabId}`)) {
                ele.classList.add('active');
            }
        });
    }

    let resetState = function(box) {
        box.querySelectorAll('.weather-box__day-slider__tile, .weather-box__content__details').forEach(function(c) {
            c.classList.remove('active');
        })

        weatherBoxWarnings.forEach( ele => ele.classList.remove('active') );
    }

    let setFirstAsActive = function(weatherBox) {
        const firstSliderTile = weatherBox.querySelector('.weather-box__day-slider .weather-box__day-slider__tile');
        const firstContent = weatherBox.querySelector('.weather-box__content__details');

        if (!firstSliderTile.classList.contains('active')) {
            firstSliderTile.classList.add('active');
        }

        if (!firstContent.classList.contains('active')) {
            firstContent.classList.add('active');
        }
    }
};

document.addEventListener('DOMContentLoaded', initWeatherBox);