document.addEventListener('DOMContentLoaded', function() {
    const printButton = document.querySelector('.social-share__button--print');

    if (printButton) {
        printButton.addEventListener('click', function(e) {
            e.preventDefault();
            window.print();
        });
    }
});
